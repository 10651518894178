import { createStore } from "vuex";
import axios from "axios";
import { TowVehicleServiceBookingStatus, } from "../src/interfaces/main-data";
import { TowVehicleMediaType, } from "../src/interfaces/tow-vehicle-media";
import { getApiBaseUrl } from "../src/apiConfig";
export default createStore({
    state: {
        images: [],
        gpsToken: "",
        isDestinationReached: false,
        vehicleDetails: {},
        towVehicleStatus: TowVehicleServiceBookingStatus.PENDING,
        towVehicle: {},
        roadAssistanceId: "",
        liveLocation: {},
        towVehicleInitialLocation: "",
    },
    mutations: {
        addImage(state, image) {
            state.images.push({
                ...image,
                type: state.towVehicleStatus === TowVehicleServiceBookingStatus.ARRIVED ||
                    state.towVehicleStatus === TowVehicleServiceBookingStatus.STARTED
                    ? TowVehicleMediaType.ARRIVED
                    : TowVehicleMediaType.COMPLETED,
            });
        },
        removeImage(state, index) {
            state.images.splice(index, 1);
        },
        setGpsToken(state, token) {
            state.gpsToken = token;
        },
        setVehicleDetails(state, details) {
            state.vehicleDetails = details;
        },
        setTowVehicleStatus(state, status) {
            state.towVehicleStatus = status;
        },
        setIsDestinationReached(state, value) {
            state.isDestinationReached = value;
        },
        setImages(state, value) {
            state.images = value;
        },
        setTowVehicle(state, details) {
            state.towVehicle = details;
        },
        setRoadAssistanceId(state, id) {
            state.roadAssistanceId = id;
        },
        seLiveLocation(state, liveLocation) {
            state.liveLocation = liveLocation;
        },
        setTowVehicleInitialLocation(state, initialLocation) {
            state.towVehicleInitialLocation = initialLocation;
        },
    },
    actions: {
        async uploadImage({ commit, dispatch }, file) {
            const formData = new FormData();
            formData.append("file", file);
            try {
                const response = await fetch(`${getApiBaseUrl() || process.env.VUE_APP_BASE_URL}/upload`, {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error("Image upload failed");
                }
                const data = await response.json();
                commit("addImage", data.data);
                await dispatch("UpdateImage");
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error("Image upload failed:", error.message);
                }
                else {
                    console.error("Unexpected error:", error);
                }
            }
        },
        async UpdateImage({ getters }) {
            try {
                const data = {
                    media: getters?.allImages,
                    towVehicleId: getters?.towVehicle?._id,
                };
                // const response = await fetch(
                await fetch(`${getApiBaseUrl() || process.env.VUE_APP_BASE_URL}/tow-vehicle/road-assistance-media/${getters.roadAssistanceId}`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
                // const res = await response?.json();
                // console.log("res", res);
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error("image status update failed", error.message);
                }
                else {
                    console.error("Unexpected error:", error);
                }
            }
        },
        removeImage({ commit }, index) {
            commit("removeImage", index);
        },
        async fetchGpsProviderToken({ commit }) {
            const baseUrl = getApiBaseUrl() || process.env.VUE_APP_BASE_URL;
            const maxRetries = 3;
            let attempts = 0;
            while (attempts < maxRetries) {
                try {
                    const response = await fetch(`${baseUrl}/tow-vehicle/gps-token-provider`);
                    const data = await response.json();
                    commit("setGpsToken", data.data.token);
                    return; // Exit the function if successful
                }
                catch (error) {
                    attempts++;
                    if (error instanceof Error) {
                        console.error(`Attempt ${attempts}: GPS Token fetch failed`, error.message);
                    }
                    else {
                        console.error(`Attempt ${attempts}: Unexpected error`, error);
                    }
                    if (attempts >= maxRetries) {
                        throw new Error("Failed to fetch GPS Token after 3 attempts");
                    }
                }
            }
        },
        async fetchVehicleDetails({ commit, getters }, deviceId) {
            let gpsToken = getters.gpsToken;
            const maxRetries = 3;
            let attempts = 0;
            if (!gpsToken) {
                await this.dispatch("fetchGpsProviderToken");
                gpsToken = getters.gpsToken;
            }
            while (attempts < maxRetries) {
                try {
                    const response = await axios.get(`https://mylocatorplus.com/gateway/index.php/api-v1/user/getVehicleDetails/${deviceId}`, {
                        headers: {
                            Xtoken: `${gpsToken}`,
                        },
                    });
                    const vehicleDetails = response.data;
                    commit("setVehicleDetails", vehicleDetails);
                    // console.log("vehicleDetails", vehicleDetails);
                    return; // Exit the function if successful
                }
                catch (error) {
                    attempts++;
                    if (axios.isAxiosError(error)) {
                        console.error(`Attempt ${attempts}: Fetching vehicle details failed`, error.message);
                    }
                    else if (error instanceof Error) {
                        console.error(`Attempt ${attempts}: Fetching vehicle details failed`, error.message);
                    }
                    else {
                        console.error(`Attempt ${attempts}: Unexpected error`, error);
                    }
                    if (axios.isAxiosError(error) &&
                        error.response?.status === 401 &&
                        attempts < maxRetries) {
                        await this.dispatch("fetchGpsProviderToken");
                        gpsToken = getters.gpsToken;
                    }
                    else if (attempts >= maxRetries) {
                        throw new Error("Failed to fetch vehicle details after 3 attempts");
                    }
                }
            }
        },
        async updateTowVehicleStatus({ commit }, status) {
            commit("setTowVehicleStatus", status);
        },
        startVehicleDetailsPolling({ dispatch, state }, deviceId) {
            let isPolling = true;
            const fetchPeriodically = async () => {
                if (isPolling &&
                    (state.towVehicleStatus === TowVehicleServiceBookingStatus.STARTED ||
                        state.towVehicleStatus === TowVehicleServiceBookingStatus.PICKUP) &&
                    !state.isDestinationReached) {
                    await dispatch("fetchVehicleDetails", deviceId);
                    setTimeout(fetchPeriodically, 30000); // 30 seconds interval
                }
            };
            fetchPeriodically();
            // Return a function to stop polling if needed
            return () => {
                isPolling = false;
            };
        },
        async fetchLiveLocation({ commit }, deviceId) {
            const baseUrl = getApiBaseUrl() || process.env.VUE_APP_BASE_URL;
            const maxRetries = 3;
            let attempts = 0;
            while (attempts < maxRetries) {
                try {
                    const response = await fetch(`${baseUrl}/tow-vehicle/live-location/${deviceId}`);
                    const data = await response.json();
                    commit("seLiveLocation", data?.data?.location);
                    return; // Exit the function if successful
                }
                catch (error) {
                    attempts++;
                    if (axios.isAxiosError(error)) {
                        console.error(`Attempt ${attempts}: Fetching vehicle details failed`, error.message);
                    }
                    else if (error instanceof Error) {
                        console.error(`Attempt ${attempts}: Fetching vehicle details failed`, error.message);
                    }
                    else {
                        console.error(`Attempt ${attempts}: Unexpected error`, error);
                    }
                    // if (
                    //   axios.isAxiosError(error) &&
                    //   error.response?.status === 401 &&
                    //   attempts < maxRetries
                    // ) {
                    //   await this.dispatch("fetchGpsProviderToken");
                    //   gpsToken = getters.gpsToken;
                    // } else if (attempts >= maxRetries) {
                    //   throw new Error("Failed to fetch vehicle details after 3 attempts");
                    // }
                }
            }
        },
        startLiveLocationPolling({ dispatch, state }, deviceId) {
            let isPolling = true;
            const fetchPeriodically = async () => {
                if (isPolling &&
                    (state.towVehicleStatus === TowVehicleServiceBookingStatus.STARTED ||
                        state.towVehicleStatus === TowVehicleServiceBookingStatus.PICKUP) &&
                    !state.isDestinationReached) {
                    await dispatch("fetchLiveLocation", deviceId);
                    setTimeout(fetchPeriodically, 30000); // 30 seconds interval
                }
            };
            fetchPeriodically();
            // Return a function to stop polling if needed
            return () => {
                isPolling = false;
            };
        },
    },
    getters: {
        allImages(state) {
            return state.images;
        },
        gpsToken(state) {
            return state.gpsToken;
        },
        vehicleDetails(state) {
            return state.vehicleDetails;
        },
        towVehicleStatus(state) {
            return state.towVehicleStatus;
        },
        isDestinationReached(state) {
            return state.isDestinationReached;
        },
        towVehicle(state) {
            return state.towVehicle;
        },
        roadAssistanceId(state) {
            return state.roadAssistanceId;
        },
        liveLocation(state) {
            return state.liveLocation;
        },
        towVehicleInitialLocation(state) {
            return state.towVehicleInitialLocation;
        },
    },
});
