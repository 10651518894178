export const getApiBaseUrl = () => {
    const domain = window.location.hostname;
    switch (domain) {
        case "automotive-roadassistancewebapp-staging.ispgnet.com":
            return "https://ultimate-backend.common-preprod.ispgnet.com/api";
        case "almulla-roadassistancewebapp-develop.ispgnet.com":
            return "https://almulla-common-crm-backend.ispgnet.com/api";
        case "stellantis-roadassistancewebapp-common-develop.ispgnet.com":
            return "https://stellantis-backend.common-preprod.ispgnet.com/api";
        case "aljazirah-roadassistancewebapp-common-develop.ispgnet.com":
            return "https://aljazirah-ford-common-crm-backend.ispgnet.com/api";
        case "dmc-roadassistancewebapp-common-develop.ispgnet.com":
            return "https://dmc-common-crm-backend.ispgnet.com/api";
        case "almulla-roadassistancewebapp-preprod.ispgnet.com":
            return "https://almulla-ford-backend.common-preprod.ispgnet.com/api";
        case "dmc-roadassistancewebapp-preprod.ispgnet.com":
            return "https://dmc-ford-backend.common-preprod.ispgnet.com/api";
        case "localhost":
            return "https://almulla-common-crm-backend.ispgnet.com/api";
        default:
            return "https://ultimate-backend.common-preprod.ispgnet.com/api";
    }
};
export const getAppName = () => {
    const domain = window.location.hostname;
    switch (domain) {
        case "automotive-roadassistancewebapp-staging.ispgnet.com":
            return "ultimate";
        case "almulla-roadassistancewebapp-develop.ispgnet.com":
            return "almulla";
        case "stellantis-roadassistancewebapp-common-develop.ispgnet.com":
            return "stellantis";
        case "aljazirah-roadassistancewebapp-common-develop.ispgnet.com":
            return "aljazirah";
        case "dmc-roadassistancewebapp-common-develop.ispgnet.com":
            return "dmc";
        case "almulla-roadassistancewebapp-preprod.ispgnet.com":
            return "almulla";
        case "dmc-roadassistancewebapp-preprod.ispgnet.com":
            return "dmc";
        case "localhost":
            return "almulla";
        default:
            return "ultimate";
    }
};
