export var TowVehicleServiceBookingStatus;
(function (TowVehicleServiceBookingStatus) {
    TowVehicleServiceBookingStatus["PENDING"] = "pending";
    TowVehicleServiceBookingStatus["STARTED"] = "started";
    TowVehicleServiceBookingStatus["ARRIVED"] = "arrived";
    TowVehicleServiceBookingStatus["PICKUP"] = "pickup";
    TowVehicleServiceBookingStatus["COMPLETED"] = "completed";
    TowVehicleServiceBookingStatus["CANCELLED"] = "cancelled";
})(TowVehicleServiceBookingStatus || (TowVehicleServiceBookingStatus = {}));
export var TowVehicleLocationType;
(function (TowVehicleLocationType) {
    TowVehicleLocationType["STARTED"] = "started";
    TowVehicleLocationType["ARRIVED"] = "arrived";
    TowVehicleLocationType["PICKUP"] = "pickup";
    TowVehicleLocationType["COMPLETED"] = "completed";
    TowVehicleLocationType["CANCELLED"] = "cancelled";
})(TowVehicleLocationType || (TowVehicleLocationType = {}));
export var TowVehicleType;
(function (TowVehicleType) {
    TowVehicleType["PICKUP"] = "pickup";
    TowVehicleType["DROPOFF"] = "dropoff";
})(TowVehicleType || (TowVehicleType = {}));
// type Data = {
//   location: ILocation;
// };
