<template>
  <div class="signature-container">
    <canvas
      ref="canvas"
      class="signature-canvas"
      width="400"
      height="300"
      @mouseout="handleMouseOut"
      @touchend="handleTouchEnd"
    />
  </div>
  <button @click="clearSignature">Reset</button>
</template>

<script>
import SignaturePad from "signature_pad"; // Importing signature pad library
import axios from "axios";
import { getApiBaseUrl } from "../../apiConfig";

export default {
  name: "SignaturePad",
  data() {
    return {
      signaturePad: null,
    };
  },
  mounted() {
    const canvas = this.$refs.canvas;
    this.signaturePad = new SignaturePad(canvas); // Initialize the SignaturePad
  },
  methods: {
    clearSignature() {
      this.signaturePad.clear(); // Clears the signature pad
    },
    saveSignature() {
      if (this.signaturePad.isEmpty()) {
        alert("Please sign the pad before saving.");
        return;
      }
      const dataUrl = this.signaturePad.toDataURL(); // Get the base64 data URL
      this.$emit("signatureSaved", dataUrl); // Emit the signature data to the parent
      console.log("Saved signature:", dataUrl);
    },
    async handleMouseOut() {
      if (!this.signaturePad.isEmpty()) {
        const dataUrl = this.signaturePad.toDataURL(); // Get the base64 data URL

        console.log("Mouse out: Saved signature", dataUrl);
        const baseUrl = getApiBaseUrl() || process.env.VUE_APP_BASE_URL;
        const response = await axios.post(
          `${baseUrl}/tow-vehicle/upload-signature`,
          { signature: dataUrl }
        );
        console.log("response", response?.data?.data);
        this.$emit("signatureSaved", response?.data?.data); // Emit the signature data when mouse leaves
      }
    },
    async handleTouchEnd() {
      if (!this.signaturePad.isEmpty()) {
        const dataUrl = this.signaturePad.toDataURL(); // Get the base64 data URL

        console.log("Mouse out: Saved signature", dataUrl);
        const baseUrl = getApiBaseUrl() || process.env.VUE_APP_BASE_URL;
        const response = await axios.post(
          `${baseUrl}/tow-vehicle/upload-signature`,
          { signature: dataUrl }
        );
        console.log("response", response?.data?.data);
        this.$emit("signatureSaved", response?.data?.data); // Emit the signature data when mouse leaves
      }
    },
  },
};
</script>

<style scoped>
.signature-container {
  position: relative;
  width: 100%;
  /* max-width: 500px; */
  margin: auto;
}

.signature-canvas {
  border: 1px solid #000;
  /* width: 100%; */
  /* height: 200px; */
  /* display: block; */
}

button {
  background: transparent;
  color: var(--primary);
  border: 0;
  padding: 2px 0px;
  text-decoration: underline;
  margin-left: 400px;
  position: relative;
  transform: translateX(-100%);
}
</style>
